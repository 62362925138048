body {
  font: 12px/1.2 "Helvetica Neue",Helvetica,Arial,sans-serif;
  box-sizing: border-box;
  color: #666;
}

h2 {
  font-size: 16px;
}

// button {
//   cursor: pointer;
//   background: $azzurro;
//   border-radius: 5px;
//   border: 1px solid darken($azzurro, 10%);
//   color: white;
//   cursor: pointer;
//   padding: 10px;

//   &:hover {
//     background-color: lighten($azzurro, 10%);
//     border: 1px solid $azzurro;
//   }
// }

strong {
  font-weight: bold;
}

.modal {
  display: none;
}

.wrapper {
  display: flex;
  flex-direction: row;
  
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;

  .summary-container {
    flex: 0 0 300px;
    border-right: 1px solid #ddd;
    padding: 10px 20px;  
    display: flex;
    flex-direction: column;

    .logo {
      text-align: center;
    }

    .summary {
      overflow-y: auto; 

    }

    .summary::-webkit-scrollbar {
          width: 0px;
          background: transparent; /* make scrollbar transparent */
        }
  }

  .chart-container {
    flex: 1;

    display: flex;
    flex-direction: column;
    height: 100%;
    
    .chart {
      background: #eee;
      border-bottom: 1px solid #ddd;
      flex: 5;
      height: 0;
    }
    
    .actions {
      
      justify-content: flex-end;

      box-sizing: border-box;
      flex: 0 0 280px;
      padding: 20px;
    }
  }
  


}

strong.warning {
  color: $warning-color;
}

.table-vertical {

  margin: 0 20px 20px 0;

  th, td { padding: 5px 10px 0 0 }
  th { 
    font-weight: bold;
    text-align: left;
  }
}