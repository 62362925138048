.exportDialog {
  position: fixed;
  top:0; left:0; right:0; bottom:0;
  background: rgba(50, 50, 50, 0.5);
  text-align: center;
}

.dialog {
  display: inline-block;
  background: white;
  border-radius: 20px;
  box-shadow: 2px 2px 10px rgba(100, 100, 100, 0.7);
  padding: 30px 50px;
  margin-top: 10%;

  header {
    margin-bottom: 20px;
    font-weight: bold;
  }

  p {
    margin-bottom: 10px;
  }

  footer {
    margin-top: 20px;
  }
}