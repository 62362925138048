.focusLine {
  fill: none;
  stroke: steelblue;
  stroke-width: 0.5px;
}

.tick text {
  font-size: 11px
}

@media (max-width: 600px) {
  .grafico {
    display: none;
  }
}

.filteredInfo {
  margin-top: 20px;
}

.filteredData {

  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;

  // hr {
  //     clear: both;
  // }
}

