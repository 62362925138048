.summary {
  margin-top: 10px;

  header {
    background: $azzurrino;
    color: white;
    padding: 5px 10px;
  }

  section > div {
    padding: 10px;
  }

  p {
    margin-bottom: 3px;
  }
}

label {
  color: $azzurrino;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 10px;
  display:inline-block;
  width: 100%;
  border-bottom: 1px dotted #ddd
}

label span {
  color: #000;
  font-size: 11px;
  float: right;
  text-transform: none;
}

.alerts {
  i.fa-check {color: #0b0;}
  i.fa-remove {color: red;}
}
// i {
//   display: inline-block;
//   width: 8px;
//   height: 8px;
//   border-radius: 4px;
//   background: #0f0;

//   &.warning {
//     background: red;
//   }
//   margin-left: 5px;
// }

.stats {

  .rh td {
    padding-top: 5px
  }

  label {
    border: none
  }
}